#slider {
    button {
        &.basic {
            box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) !important;
            border-left: none !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    .label::before {
        display: none !important;
    }

    .progress {
        &.label {
            box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) !important;
            background-color: transparent !important;
        }

        .bar {
            height: 100%;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}
