.hover-container {
    padding: 0 !important;
    margin: 0 !important;
    border: 0;

    .hide-on-hover {
        display: inline-block !important;
        margin: 0 !important;
    }

    .show-on-hover {
        display: none !important;
        margin: 0 !important;
    }

    &:hover {
        .hide-on-hover {
            display: none !important;
        }

        .show-on-hover {
            display: inline-block !important;
        }
    }
}

.hover-container+.hover-container
{
    margin: 0 0 0 .25em !important;
}
