.ui.horizontal.divider {
    &.custom {
        margin: 0.5em -1em !important;
        text-transform: none !important;

        &::before {
            background-position: right 0.5em top 50%;
            opacity: 0.4;
        }

        &::after {
            background-position: left 0.5em top 50%;
            opacity: 0.4;
        }
    }
}
